import React, { useState } from 'react';
import axios from 'axios';
// import useScript from '../hooks/useScript';
// import { Link } from 'gatsby';
//  import JSONData from '../content/artgroup.json';

function DigitalPublication() {
  // useScript('/libs/mobile_script.js');

  const [books, setBooks] = useState(null);

  // const fetchBallet = async () => {
  //   let response = await axios.get('/content/ballet-west.json');
  //   setBooks(response.data);
  // };

  // const fetchBroadway = async () => {
  //   let response = await axios.get('/content/broadway-at-the-eccles.json');
  //   setBooks(response.data);
  // };

  // const fetchBroadwayBoise = async () => {
  //   let response = await axios.get('/content/broadway-in-boise.json');
  //   setBooks(response.data);
  // };

  // const fetchDVMF = async () => {
  //   let response = await axios.get('/content/dvmf.json');
  //   setBooks(response.data);
  // };

  // const fetchGrand = async () => {
  //   let response = await axios.get('/content/grand-theatre.json');
  //   setBooks(response.data);
  // };

  // const fetchBestor = async () => {
  //   let response = await axios.get('/content/kurt-bestor.json');
  //   setBooks(response.data);
  // };

  // const fetchOdyssey = async () => {
  //   let response = await axios.get('/content/odyssey-dance-theatre.json');
  //   setBooks(response.data);
  // };

  // const fetchOgden = async () => {
  //   let response = await axios.get('/content/onstage-ogden.json');
  //   setBooks(response.data);
  // };

  // const fetchPioneer = async () => {
  //   let response = await axios.get('/content/pioneer-theatre-company.json');
  //   setBooks(response.data);
  // };

  // const fetchSLAC = async () => {
  //   let response = await axios.get('/content/salt-lake-acting-company.json');
  //   setBooks(response.data);
  // };

  // const fetchTuacahn = async () => {
  //   let response = await axios.get('/content/tuacahn.json');
  //   setBooks(response.data);
  // };

  // const fetchUAF = async () => {
  //   let response = await axios.get('/content/utah-arts-festival.json');
  //   setBooks(response.data);
  // };

  // const fetchUFO = async () => {
  //   let response = await axios.get('/content/utah-festival-opera.json');
  //   setBooks(response.data);
  // };

  // const fetchOpera = async () => {
  //   let response = await axios.get('/content/utah-opera.json');
  //   setBooks(response.data);
  // };

  // const fetchUtahPresents = async () => {
  //   let response = await axios.get('/content/utahpresents.json');
  //   setBooks(response.data);
  // };

  // const fetchBard = async () => {
  //   let response = await axios.get('/content/utah-shakespeare-festival.json');
  //   setBooks(response.data);
  // };

  // const fetchSymphony = async () => {
  //   let response = await axios.get('/content/utah-symphony.json');
  //   setBooks(response.data);
  // };

  const fetchRealtor = async () => {
    let response = await axios.get('/content/realtor.json');
    setBooks(response.data);
  };

  const fetchDental = async () => {
    let response = await axios.get('/content/utah-dental.json');
    setBooks(response.data);
  };

  const fetchPhysician = async () => {
    let response = await axios.get('/content/utah-physician.json');
    setBooks(response.data);
  };

  // const fetchFootball = async () => {
  //   let response = await axios.get('/content/utah-football.json');
  //   setBooks(response.data);
  // };

  // const fetchBasketball = async () => {
  //   let response = await axios.get('/content/utah-basketball.json');
  //   setBooks(response.data);
  // };

  // const fetchGymnastics = async () => {
  //   let response = await axios.get('/content/utah-gymnastics.json');
  //   setBooks(response.data);
  // };

  return (
    <>
      <hr />
      <h3>Digital Flipbook Examples</h3>
      <h4>Arts</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/370aecc1e55242ba9138780d4222834c"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchBallet}
          >
            Ballet West
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/565cbae14e0c436e9331361e5ad2cb52"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchBroadway}
          >
            Broadway at the Eccles
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/f2e305b782cd408ea07f10216b57a003"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchBroadwayBoise}
          >
            Broadway in Boise
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/6cfda16c2abf4f1c93da037da030eec7"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchDVMF}
          >
            Deer Valley® Music Festival
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/a1e74b805d8343e4a1dcec3fedf176f3"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchGrand}
          >
            Grand Theatre
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/565cbae14e0c436e9331361e5ad2cb52"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchBestor}
          >
            Kurt Bestor
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/bfd729605a8a4ad990f2c8d5c4d83953"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchOdyssey}
          >
            Odyssey Dance Theatre
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/9d328f5ad28c41c7b4da28f69950277f"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchOgden}
          >
            Onstage Ogden
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/1edcb4a5437f4bc4b0e86e3240b6201e"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchPioneer}
          >
            Pioneer Theatre Company
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/e9e3d323fd89482e95ef5241ec745bf7"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchSLAC}
          >
            Salt Lake Acting Company
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/2a85d10966bf4bada832f23395b317bb"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchTuacahn}
          >
            Tuacahn
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/4ef50a877e204be1bda67d510ac1fe20"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchUAF}
          >
            Utah Arts Festival
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/3589796674b64cd9b87da0b58e8fcb61"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchUFO}
          >
            Utah Festival Opera
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/ca7e23c0b0bd4f3c9105ae689d6c35a2"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchOpera}
          >
            Utah Opera
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/4c01746cd016429181ea5b13569e3608"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchUtahPresents}
          >
            UtahPresents
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/8c35301e538241d3b798099f659ea541"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchBard}
          >
            Utah Shakespeare Festival
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/6cfda16c2abf4f1c93da037da030eec7"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchSymphony}
          >
            Utah Symphony
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/e08881c35e5246eba6139877b3b6f28f"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchSymphony}
          >
            Utah Symphony | Utah Opera
          </a>
        </li>
      </ul>

      <h4>University Sports Programs</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/f4c385a61950494dba057dcae6af4e71"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchFootball}
          >
            Utah Football
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/3787113c339d49a194492a531350ce2b"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchBasketball}
          >
            Utah Basketball
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/683563054b354f5e990da2e3171df0d8"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchGymnastics}
          >
            Utah Gymnastics
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/41cfd1e2c8f24bedb14f6a4fb9911ea0"
            className="button special small arts"
          >
            Utah State Football
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/f1038ed5a4a24085b90059e70c9289a7"
            className="button special small arts"
          >
            Utah State Basketball
          </a>
        </li>
      </ul>

      <h4>Professional Journals</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/1a569d39fad14904ab7ead52dd791361"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchRealtor}
          >
            Salt Lake Realtor®
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishinginc/stacks/09724518caf24b37a51f58a008c4ea49"
            className="button special small arts"
            target="_blank"
            rel="noreferrer"
            // onClick={fetchDental}
          >
            Utah Dental Association
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchPhysician}
          >
            Utah Physician
          </a>
        </li>
      </ul>

      <h4>Outdoor Sports Guide Magazine</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="https://issuu.com/sports.guide/docs"
            className="button special small arts"
          >
            Outdoor Sports Guide
          </a>
        </li>
      </ul>

      <hr />
      <div id="digipubs" className="grid-wrapper">
        {books &&
          books.issuu.map((book, index) => {
            return (
              <section key={index} className="col-6">
                <header className="major">
                  <h3>{book.title}</h3>
                </header>
                <p dangerouslySetInnerHTML={{ __html: book.desc }}></p>
                <ul className="actions">
                  <li className="bigButtons">
                    <a
                      href={book.stack}
                      className="button special"
                      target="_blank"
                      rel="noreferrer"
                      rel="noopener noreferrer"
                    >
                      See all
                    </a>
                  </li>
                </ul>
              </section>
            );
          })}
      </div>
      <ul className="actions">
        <li className="bigButtons">
          <a href="#" className="button">
            <i className="icon fa-arrow-up"> </i>Return to Top
          </a>
        </li>
      </ul>
    </>
  );
}

export default DigitalPublication;
